import React, { useEffect } from "react";
import SEO from "../components/seo";
import "../styles/about.css";

const AboutPage = ({ location }) => {
  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('revealed');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
      rootMargin: '0px 0px -50px 0px'
    });

    document.querySelectorAll('.scroll-reveal').forEach(element => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <SEO 
        title="About Me"
        description="Learn more about Tu Huynh - A nerdy software engineer from Vietnam"
        keywords={["about", "tu huynh", "vietnamese developer", "software engineer", "blog"]}
      />
      <div className="about-container">
        <div className="about-decoration about-decoration-1"></div>
        <div className="about-decoration about-decoration-2"></div>
        <div className="about-decoration about-decoration-3"></div>
        <div className="about-decoration about-decoration-4"></div>
        
        <header>
          <h1 className="about-page-title scroll-reveal">About Me</h1>
        </header>
        <article className="about-content">
          <p className="scroll-reveal scroll-reveal-delay-1">
            Hello world! I'm <span className="about-highlight">Tu</span>, just another nerdy developer who spends way too much time diving into 
            technical documentation and getting excited about how things work under the hood. I was born 
            in <span className="about-highlight">Ho Chi Minh City</span>, grew up in the beautiful coastal city of <span className="about-highlight">Da Nang</span>, and then moved back 
            to HCMC for university. Currently, I'm writing code (and occasionally breaking things) in 
            <span className="about-highlight"> Singapore</span>.
          </p>
          
          <p className="scroll-reveal scroll-reveal-delay-2">
            My journey into programming started with a curiosity about how computers work. That curiosity 
            turned into an obsession with understanding systems at a deeper level. These days, I spend 
            most of my time trying to figure out complex technical stuff and then attempting to explain 
            it in a way that hopefully makes sense (at least to other nerds like me 🤓).
          </p>

          <p className="scroll-reveal scroll-reveal-delay-3">
            When I'm not staring at a terminal or debugging code, you might find me:
          </p>
          <ul className="about-list scroll-reveal">
            <li>Surfing Reddit (to get a break from coding)</li>
            <li>Contributing to open-source projects (or at least trying to)</li>
            <li>Reading about new technical concepts (and then trying to implement them)</li>
            <li>Reading about financial markets (to understand the economy)</li>
          </ul>

          <h2 className="scroll-reveal">What I Work With</h2>
          <ul className="about-list scroll-reveal scroll-reveal-delay-1">
            <li>Full-stack Development (because why limit yourself to just one type of bug?)</li>
            <li>Performance Optimization (making slow things less slow, but not too much !?)</li>
            <li>Technical Writing (turning coffee into documentation)</li>
          </ul>

          <h2 className="scroll-reveal">Let's Connect!</h2>
          <p className="scroll-reveal scroll-reveal-delay-1">
            If you're interested in discussing software architecture, system design, or just want to 
            geek out about technology, feel free to reach out through any of the social links above. 
            I'm always happy to connect with fellow tech enthusiasts.
          </p>

          <h2 className="scroll-reveal">Recommended Newsletter</h2>
          <p className="scroll-reveal scroll-reveal-delay-1">
            I'm a big fan of newsletters. Here are some of my favorite ones:
          </p>
          <ul className="about-list scroll-reveal scroll-reveal-delay-2">
            <li>
              <a href="https://quanghoang.substack.com/" target="_blank" rel="noopener noreferrer" className="about-link">
                Quang Hoang Substack
              </a>
            </li>
            <li>
              <a href="https://hoquoctuan.substack.com/" target="_blank" rel="noopener noreferrer" className="about-link">
                Ho Quoc Tuan Substack
              </a>
            </li>
            <li>
              <a href="https://viethustler.substack.com/" target="_blank" rel="noopener noreferrer" className="about-link">
                Viet Huslter Substack
              </a>
            </li>
          </ul>
        </article>
      </div>
    </>
  );
};

export default AboutPage;
